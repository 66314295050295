@import url(https://fonts.googleapis.com/css?family=Noto+Sans|Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.hero-body{
  padding:1rem 1rem !important;
}

.navbar-link::after {
  border-color: #5cc1a1!important;
}

.navbar-link:hover {
  background-color: white!important;
  color: #0077bf!important;
}

a {
  color: #0077bf!important;
}

.navbar-item:hover {
  background-color: white!important;
}

.headerBar {
  background-color: #5cc1a1;
  height: 0.5rem;
}

.headerNav {
  border-bottom-color: #5cc1a1!important;  
  border-bottom: solid;
  border-bottom-width: 10px;
}

.image{
  width:150px !important;
}

.card {
  border-radius: 4px; 
}

.section{
  padding: 1.5rem 1rem !important;
}



h1, .card-header-title{
  font-family: 'Noto Sans', sans-serif;
}

.content, .card-footer-item, .card-header-icon{
  font-family: 'Open Sans', sans-serif;
}

.showMore:hover{
  cursor: pointer;
  color:#5cc1a1;
}


